<template>
  <div>
    <h3 class="black--text mb-5">
      {{
        $t("text.titulo_desejo_recusar_somente_programa", {
          programa: "SBRHVI"
        })
      }}
    </h3>

    <p class="black--text">{{ $t("text.info_recusar_programa_sbrhvi") }}</p>
  </div>
</template>

<script>
export default {
  name: "RefuseOnlySbrhvi"
};
</script>

<style lang="scss" scoped></style>
