import { render, staticRenderFns } from "./TermSbrHvi.vue?vue&type=template&id=49d342e2&scoped=true&"
import script from "./TermSbrHvi.vue?vue&type=script&lang=js&"
export * from "./TermSbrHvi.vue?vue&type=script&lang=js&"
import style0 from "./TermSbrHvi.vue?vue&type=style&index=0&id=49d342e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d342e2",
  null
  
)

export default component.exports