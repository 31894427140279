import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      inviteDetail: "invited/getInviteDetail"
    })
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("invite", ["sendNotionAbrBci", "sendNotionSbrHvi"]),

    async callSendNotionAbrBci(acceptAbr, acceptBci, acceptSbrhvi, onSuccess) {
      try {
        const {
          produtor_id,
          tipo_produtor,
          unidade_produtiva_id
        } = this.inviteDetail;

        const response = await this.sendNotionAbrBci({
          produtor_id,
          tipo_produtor,
          unidade_produtiva_id,
          aceite_bci: acceptAbr ? acceptBci : false,
          aceitou: acceptAbr,
          aceite_sbrhvi: acceptSbrhvi
        });

        this.toggleSnackbar({
          text:
            response.detail ??
            this.$t("text.success_invite", {
              situacao: acceptAbr ? "aceito" : "cancelado"
            }),
          type: "success"
        });
        onSuccess();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async callSendNotionSbrHvi(accepted, onSuccess) {
      try {
        const { produtor_id, tipo_produtor } = this.inviteDetail;

        const response = await this.sendNotionSbrHvi({
          produtor_id,
          tipo_produtor,
          aceitou: accepted
        });

        this.toggleSnackbar({
          text:
            response.detail ??
            this.$t("text.success_invite", {
              situacao: accepted ? "aceito" : "cancelado"
            }),
          type: "success"
        });
        onSuccess();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
