export const InvitedModes = {
  ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED: {
    id: 1,
    description: "Convidado para o programa ABR/BCI e SBRHVI não aceito"
  },
  ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED: {
    id: 2,
    description: "Convidado para o programa ABR/BCI e SBRHVI aceito"
  },
  ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED: {
    id: 3,
    description:
      "Convidado apenas para o programa ABR/BCI, programa SBRHVI não aceito"
  },
  ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED: {
    id: 4,
    description:
      "Convidado apenas para o programa ABR/BCI, programa SBRHVI aceito"
  },
  SBRHVI_UNACCEPTED: {
    id: 5,
    description:
      "Convidado somente para o programa SBRHVI, convide ainda não aceito"
  },
  SBRHVI_ACCEPTED: {
    id: 6,
    description: "Convidado somente para o programa SBRHVI, convide aceito"
  },
  ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED: {
    id: 7,
    description:
      "Convidado possui exceção do sistema SBRHVI, sem responsável SBRHVI"
  },
  ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED: {
    id: 8,
    description:
      "Convidado possui exceção do sistema SBRHVI e possui responsável SBRHVI"
  }
};
