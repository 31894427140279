<template>
  <div>
    <v-card-text>
      <term-sbr-hvi />
    </v-card-text>

    <v-card-actions class="d-flex justify-end pb-5 pr-5">
      <v-btn
        color="refuse"
        class="px-4 white--text"
        @click="
          $confirmDialog.open(
            {
              title:
                'Você tem certeza de que deseja recusar o Convite para o Programa SBRHVI?',
              infoText:
                'A partir de 2021, todos os produtores que aderirem ao ABR, ABR/BCI, automaticamente, aderem ao SBRHVI por prazo indeterminado.'
            },
            handleRefuseTerm
          )
        "
      >
        Desejo recusar o Programa SBRHVI
      </v-btn>
      <v-btn
        color="accept"
        class="px-4 white--text"
        @click="
          $confirmDialog.open(
            'Você confirma a adesão ao Programa SBRHVI?',
            handleAcceptTerm
          )
        "
      >
        Estou ciente e desejo aderir ao Programa SBRHVI</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import TermSbrHvi from "@/modules/invited/views/components/sbrhvi/TermSbrHvi";
import NotionMixin from "@/modules/invited/mixins/notion-mixin";

import { mapActions } from "vuex";

export default {
  name: "StepperContentSbrhvi",

  components: {
    TermSbrHvi
  },

  mixins: [NotionMixin],

  data: () => ({
    termAbrBciState: {
      acceptBci: null
    }
  }),

  mounted() {
    this.setTermPageFirstStepLabel("Termos de Adesão SBRHVI");
  },

  methods: {
    ...mapActions("invited", [
      "setTermPageFirstStepLabel",
      "setTermPageCurrentStep"
    ]),

    handleAcceptTerm() {
      this.callSendNotionSbrHvi(true, () => {
        this.setTermPageCurrentStep(2);
      });
    },

    handleRefuseTerm() {
      this.callSendNotionSbrHvi(false, () => {
        this.setTermPageCurrentStep(2);
      });
    }
  }
};
</script>

<style></style>
