<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="3">
        <labelled-info label="Produtor">
          {{ termData.unidade_produtiva.produtor }}
        </labelled-info>
      </v-col>
      <v-col cols="3">
        <labelled-info label="Unidade Produtiva">
          {{ termData.unidade_produtiva.nome }}
        </labelled-info>
      </v-col>
    </v-row>
    <h2 class="my-5 primary--text word-break">
      TERMO DE ADESÃO AO PROGRAMA ALGODÃO BRASILEIRO RESPONSÁVEL – ABR COM OPÇÃO
      DE ADESÃO AO LICENCIAMENTO DE COMERCIALIZAÇÃO DE <i>BETTER COTTON</i> – BCI –
      SAFRA 2023/2024
    </h2>

    <h3 class="secondary-text--text word-break">
      NOMEAÇÃO DE REPRESENTANTE E DECLARAÇÃO ÉTICA DE COMPROMISSO COM A PRÁTICA
      DA SUSTENTABILIDADE
    </h3>

    <p class="ma-0 my-5 primary--text word-break">
      <strong>Anexo II do Regulamento</strong>
    </p>

    <div class="d-flex fill">
      <v-card class="ml-2 flex-1" color="grey lighten-3">
        <v-card-title class="primary-text--text word-break">
          Dados da Unidade Produtiva
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row class="mt-3">
                <v-col cols="12">
                  <labelled-info cols="12" label="Unidade Produtiva">
                    {{ termData.unidade_produtiva.nome || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12">
                  <labelled-info cols="12" label="Estado UF">
                    {{ termData.unidade_produtiva.uf || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12">
                  <labelled-info label="E-mail">
                    {{ termData.unidade_produtiva.email || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12">
                  <labelled-info cols="12" label="Associação Estadual">
                    {{
                      termData.unidade_produtiva.associacao_estadual.sigla ||
                      "Não informado"
                    }}
                  </labelled-info>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row class="mt-3">
                <v-col cols="12">
                  <labelled-info cols="12" label="Produtor / Grupo">
                    {{ termData.unidade_produtiva.produtor || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12">
                  <labelled-info cols="12" label="Município">
                    {{ termData.unidade_produtiva.municipio || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-row class="mt-3">
                  <labelled-info style="position: absolute;" cols="12" label="Telefone (DDD/Nº)">
                    {{ termData.unidade_produtiva.telefone || "Não informado" }}
                  </labelled-info>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <labelled-info label="Associação Estadual" v-if="termData.unidade_produtiva.associacao_estadual &&
                termData.unidade_produtiva.associacao_estadual.length
                ">
                {{
                  `${termData.unidade_produtiva.associacao_estadual.razao_social} -
                                ${termData.unidade_produtiva.associacao_estadual.sigla}`
                }}
              </labelled-info>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="ml-2 flex-1" color="grey lighten-3">
        <v-card-title class="primary-text--text word-break">
          Dados do Responsável pela Unidade Produtiva
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <labelled-info label="Nome">
                {{ termData.unidade_produtiva.responsavel.nome }}
              </labelled-info>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12">
              <labelled-info cols="12" label="E-mail">
                {{ termData.unidade_produtiva.responsavel.email }}
              </labelled-info>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12">
              <labelled-info cols="12" label="Telefone (DDD/Nº)">
                {{ termData.unidade_produtiva.responsavel.telefone }}
              </labelled-info>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div class="word-break">
      <p class="mt-5 word-break">
        Por meio do presente termo, a unidade produtiva/grupo/produtor acima
        identificada solicita sua adesão ao
        <strong>Programa Algodão Brasileiro Responsável – ABR e ao Programa Standard
          Brasil HVI (SBRHVI)</strong>, este último por prazo indeterminado, autorizando a equipe técnica da
        Associação Estadual e os auditores da certificadora contratada,
        devidamente credenciados e identificados, a proceder à verificação de
        campo em sua unidade produtiva e no escritório responsável pela
        documentação dos recursos humanos e constatar através da
        <strong>(1) lista de Verificação para Diagnóstico da Propriedade (VDP) e (2)
          lista de Verificação para Certificação da Propriedade (VCP) para a safra
          2023/2024,</strong>
        o cumprimento dos princípios legais constantes do Regulamento do Programa
        ABR, visando à obtenção do Certificado Algodão Brasileiro Responsável.
      </p>

      <p class="mt-5 word-break">
        A unidade produtiva/grupo/produtor autoriza também o uso pela Associação
        Estadual de imagens digitalizadas das conformidades e adequações
        executadas na unidade produtiva.
      </p>

      <p class="word-break">
        A unidade produtiva/grupo/produtor, por meio de seu representante legal
        abaixo assinado e qualificado, declara espontaneamente e, sob compromisso
        de honra, que tem conhecimento dos princípios éticos e legais que integram
        o regulamento de concessão do Certificado e do Selo ABR. Declara, também,
        que sua produção agropecuária não utiliza, nem utilizará, mão de obra
        infantil nem qualquer forma de trabalho forçado ou análogo ao escravo,
        degradante ou indigno nas relações trabalhistas.
      </p>

      <p class="word-break">
        A unidade produtiva/grupo/produtor reconhece ainda que o processo de
        certificação do Programa ABR será celebrado por ela ou pela Associação
        Estadual diretamente com a empresa certificadora credenciada pela Abrapa.
      </p>

      <p>
        <strong class="word-break">Toda unidade produtiva certificada ABR pode receber, também, por livre opção, o
          licenciamento para
          comercialização de créditos da <i>BETTER COTTON</i>.</strong>
      </p>

      <h2 class="mt-5 mb-5 primary--text word-break">
        Protocolo ABR - Licenciamento <i>Better Cotton</i> e Compromissos do Produtor
      </h2>

      <h3 class="mb-2">
        <span class="latter mr-2 word-break">A.</span> Redução do uso de defensivos agrícolas
      </h3>
      <p class="word-break">
        A partir de 2021/22, a Abrapa e a <i>Better Cotton</i> concordam que irão
        trabalhar em conjunto na busca de alternativas para minimizar o uso dos
        seguintes ingredientes ativos nas lavouras de algodão brasileiras, devido
        aos seus possíveis efeitos nocivos à saúde humana e ao meio ambiente:
        Abamectina, Beta-cyfluthrin, Carbosulfan, Chlorpyrifos, Bifentrina, Fentin
        hydroxide, Lambda-ciatrolina, Metomil, Mancozeb, Zetacipermetrina e
        Flumioxazina.
      </p>
      <p class="word-break">
        Os itens de CMP do protocolo ABR foram ajustados na VDP/VCP, no item 8.11
        para cumprir os requisitos de licenciamento da <i>Better Cotton</i>, e proíbem o
        uso de pesticidas listados em:
      </p>
      <ul class="word-break">
        <li>Anexos A e B da Convenção de Estocolmo;</li>
        <li>Anexos do Protocolo de Montreal e</li>
        <li>Anexo III da Convenção de Rotterdam &sup1;.
          <ul class="mt-1">
            <li class="ml-5 word-break">&sup1; A Convenção de Rotterdam sobre Consentimento Prévio Informado (PIC)
              regulamenta a
              troca de informações no comércio
              internacional sobre determinados pesticidas perigosos (princípios ativos e formulações). <strong>Alguns dos
                princípios ativos listados
                ou propostos para a lista são usados no algodão no Brasil. A Abrapa avisará se/quando um produto químico
                for
                adicionado ao Anexo III e fornecerá orientações adicionais aos produtores</strong>.</li>
          </ul>
        </li>
      </ul>

      <h3 class="mt-5 mb-2 word-break">
        <span class="latter mr-2 word-break">B.</span> Requisitos da Cadeia de Custódia
        <i>Better Cotton</i>
      </h3>
      <p class="word-break">
        Para aderir ao licenciamento da <i>Better Cotton</i>, o produtor deve revisar e
        concordar com os requisitos da Cadeia de Custódia (CoC) <i>Better Cotton</i>, que
        foram adaptados para Produtores certificados pelo ABR. Os requisitos da
        Cadeia de Custódia detalham as responsabilidades com relação à separação
        do algodão certificado nas algodoeiras, à garantia de que as transações de
        créditos <i>Better Cotton</i> (BCCUs) estejam vinculadas ao volume de algodão
        produzido, ao uso da plataforma BCP, e ao registro de operações. Para
        consultar os requisitos, em português, acesse:
        <a href=" https://cottonplatform.bettercotton.org/FileHandler.ashx?
                id=6a88253c-5e5e-49a1-bf45-a8c306230fb0"
          target="_blank">https://cottonplatform.bettercotton.org/FileHandler.ashx?
          id=6a88253c-5e5e-49a1-bf45-a8c306230fb0</a>. E para obter mais detalhes, consulte:
        <a href="https://bettercotton.org/better-cotton-standard-system/chain-of-custody/"
          target="_blank">https://bettercotton.org/better-cotton-standard-system/chain-of-custody/.</a>
      </p>

      <h3 class="mb-2 word-break">
        <span class="latter mr-2 word-break">C.</span> Relatório de Indicadores de Resultados
      </h3>
      <p>
        A unidade produtiva optante pelo licenciamento BCI compromete-se a
        colaborar efetuando a entrega à Associação Estadual de todas as
        informações para a elaboração do Relatório de Indicadores de Resultados,
        incluindo:
      <ul class="word-break">
        <li>Área (ha);</li>
        <li>Produção (kg de algodão em pluma);</li>
        <li>Produtividade (kg / ha de algodão em pluma);</li>
        <li>Uso de fertilizantes (litros ou quilos por hectare);</li>
        <li>Uso de defensivos (quilos ou litros de produto comercial por hectare);</li>
        <li>Uso da água - irrigação (m³);</li>
        <li>Treinamentos ofertados e número de participantes.</li>
      </ul>
      </p>

      <p class="mt-5 word-break"><strong>Atenção:</strong></p>

      <p class="word-break">
        As informações serão enviadas à <i>Better Cotton</i> Initiative,
        obrigatoriamente, sob cláusula de confidencialidade e somente serão
        divulgados dados compilados nacionalmente. Nenhum produtor/grupo jamais
        terá seus dados divulgados individualmente.
      </p>

      <v-card color="grey lighten-3">
        <v-card-title class="word-break">
          A ADESÃO AO PROCESSO DE LICENCIAMENTO BCI DEVE SER FEITA POR MEIO DESTE
          TERMO DE ADESÃO, SENDO OBRIGATÓRIA A SINALIZAÇÃO DE UMA DAS OPÇÕES A
          SEGUIR:</v-card-title>
        <v-card-text>
          <p class="primary-text--text">
            <strong class="word-break">Adesão ao Licenciamento BCI?</strong>
          </p>
          <v-radio-group v-model="state.acceptBci">
            <v-radio label="Sim" :value="'yes'"></v-radio>
            <v-radio label="Não" :value="'no'"></v-radio>
          </v-radio-group>

          <p>
            {{ $t("terms.bci_warning") }}
          </p>
        </v-card-text>
      </v-card>

      <h2 class="mt-15 mb-5 primary--text word-break">
        ANEXO DO TERMO DE ADESÃO AO PROGRAMA ALGODÃO BRASILEIRO RESPONSÁVEL – ABR – COM OPÇÃO DE ADESÃO AO LICENCIAMENTO
        DE COMERCIALIZAÇÃO DE <i>BETTER COTTON</i> – SAFRA 2023/2024
      </h2>

      <h2 class="mb-4 word-break">Minimizando os impactos prejudiciais das práticas de uso de defensivos</h2>

      <p>A Abrapa está de acordo com a intenção da <i>Better Cotton</i> Standard de minimizar os impactos prejudiciais das
        práticas de uso de defensivos na saúde humana e no meio ambiente de forma mais ampla. Como defensora
        dos produtores de algodão brasileiros e como Parceira Estratégica da <i>Better Cotton</i>, a Abrapa está
        empenhada em avaliar o uso de pesticidas altamente perigosos (HHPs) na produção de algodão brasileira
        para alcançar esse importante objetivo.</p>

      <p>O Protocolo ABR continuará a promover a adoção de abordagens de manejo integrado de pragas que
        reduzam a dependência de pesticidas e minimizem os potenciais efeitos nocivos dos pesticidas,
        principalmente na saúde humana.</p>

      <h2 class="mb-4 word-break">Desenvolvimento de Estratégia Abrapa</h2>

      <p>Por meio de consultas e engajamento com parceiros do setor e partes interessadas, a Abrapa avaliará os
        riscos dos HHPs (pesticidas altamente perigosos), identificará as melhores práticas de mitigação de risco e
        possíveis controles alternativos com o objetivo de reduzir a toxicidade total dos pesticidas aplicados no
        algodão, no decorrer do tempo. A Abrapa se dedica a este esforço importante para definir um plano sólido
        com prazo determinado para atingir tal objetivo. Os produtores serão incluídos no desenvolvimento da
        estratégia e serão notificados sobre seu desenvolvimento e/ou quaisquer alterações na classificação de um
        produto químico e/ou alterações previstas para os requisitos de licenciamento ABR - <i>Better Cotton</i>.</p>

      <h2 class="mb-4 word-break">Classificações dos HHPs (pesticidas altamente perigosos)</h2>

      <p>A toxicidade aguda refere-se às substâncias que representam um risco para a saúde humana quando há
        exposições únicas ou múltiplas (oral, dérmica ou inalatória) num curto período de tempo (geralmente menos
        de 24 horas). A substância pode causar efeitos nocivos ou letais que são evidentes imediatamente ou logo
        após a exposição.</p>

      <p>A classificação da Organização Mundial da Saúde (OMS) é baseada na toxicidade aguda, e o Sistema
        Globalmente Harmonizado de Classificação e Rotulagem de Produtos Químicos (GHS) tem categorias de
        perigo de toxicidade aguda correspondentes. Os pesticidas mais agudamente perigosos são classificados
        como:</p>
      <ul>
        <li>OMS Classe Ia: Extremamente perigoso | OMS Classe Ib: altamente perigoso</li>
        <li>GHS Categoria 1 | GHS Categoria 2</li>
      </ul>

      <p class="mt-2 word-break">Toxicidade crônica refere-se a uma substância que pode causar efeitos prejudiciais por um
        período
        prolongado, geralmente após exposição repetida ou contínua, às vezes permanecendo por toda a vida do
        organismo exposto. Os efeitos podem ser descobertos muitos anos após a exposição e podem incluir danos
        graves, irreversíveis ou mesmo letais. Além da toxicidade aguda, o GHS classifica as substâncias com base
        em riscos crônicos para a saúde e riscos ambientais. Os riscos crônicos à saúde são listados em três
        categorias: carcinogenicidade, mutagenicidade e toxicidade reprodutiva, e são chamados coletivamente de
        substâncias CMR.</p>

      <ul>
        <li><strong class="word-break">Carcinogenicidade:</strong> Um carcinógeno é uma substância ou mistura que induz
          câncer ou aumenta sua
          incidência. O GHS categoriza o potencial carcinogênico como conhecido (Categoria 1A) ou presumido
          (Categoria 1B) como tendo potencial carcinogênico para humanos.</li>
        <li><strong class="word-break">Mutagenicidade:</strong> Um mutagênico é uma substância ou mistura que pode causar
          mutações nas
          células
          germinativas dos humanos que podem ser transmitidas à sua progenitura . As substâncias da categoria 1A do
          GHS são conhecidas por induzir mutações hereditárias em células germinativas de humanos, e a categoria 1B
          do GHS deve ser considerada como se induzissem mutações hereditárias em células germinativas de
          humanos. </li>
        <li><strong class="word-break">Toxidade reprodutiva:</strong> Um repro-tóxico é uma substância ou mistura com
          efeitos adversos na
          função sexual
          e fertilidade em machos e fêmeas adultos, bem como toxicidade que afeta o desenvolvimento dos seus
          descendentes. O GHS classifica a toxicidade reprodutiva como tóxicos reprodutivos humanos conhecidos
          (Categoria 1A) ou presumidos (Categoria 1B).</li>
      </ul>
    </div>
  </div>
</template>
<script>
import LabelledInfo from "@/modules/core/views/components/LabelledInfo";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    LabelledInfo
  },
  data: () => ({
    acceptBCI: false,
    termData: {
      safra_virgente: null,
      aceite_bci: undefined,
      unidade_produtiva: {
        nome: null,
        municipio: null,
        uf: null,
        email: null,
        produtor: null,
        telefone: null,
        celular: null,
        associacao_estadual: {},
        responsavel: {
          id: null,
          nome: null,
          email: null,
          cpf: null,
          telefone: null,
          celular: null
        }
      }
    }
  }),
  async mounted() {
    await this.fetchTerm();
  },
  computed: {
    ...mapGetters({
      inviteDetail: "invited/getInviteDetail"
    })
  },
  methods: {
    ...mapActions("invite", ["fetchAbrTerm"]),
    ...mapActions(["toggleSnackbar"]),

    async fetchTerm() {
      try {
        this.termData = await this.fetchAbrTerm(this.inviteDetail);
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.latter {
  font-size: 1.4rem;
}

.word-break {
  word-break: break-word;
}
</style>
