<template>
  <div class="mt-5">
    <labelled-info v-if="showHeader" :label="mode.label">
      {{ termData.nome }}
    </labelled-info>
    <h2 class="mt-5 primary--text">
      TERMO DE ADESÃO AO PROGRAMA STANDARD BRASIL HVI
    </h2>
    <p class="primary--text">{{ mode.productionType }}</p>

    <p v-if="ProducerType.PRODUCER.id === mode.type">
      {{
        $t("terms.sbrhvi_producer", {
          nome_produtor: termData.nome,
          rg: termData.rg,
          cpf_or_cnpj: $tc(`labels.${termData.isCpf ? "cpf" : "cnpj"}`),
          cpf_cnpj: termData.cpf_cnpj,
          razao_social_associacao_estadual:
            termData.associacao_estadual.razao_social,
          sigla_associacao_estadual: termData.associacao_estadual.sigla,
          endereco: termData.endereco,
          cidade: termData.cidade,
          uf: termData.uf
        })
      }}
    </p>

    <div v-if="ProducerType.GROUP.id === mode.type">
      <p>
        {{
          $t("terms.sbrhvi_group_1", {
            nome_grupo: termData.nome,
            razao_social_associacao_estadual:
              termData.associacao_estadual.razao_social,
            sigla_associacao_estadual: termData.associacao_estadual.sigla
          })
        }}
      </p>

      <p
        :key="`cotonicutor-${index}`"
        v-for="(cotonicultor, index) in termData.composicao"
      >
        {{ `${cotonicultor.nome} - ${cotonicultor.cpf_cnpj}` }}
      </p>

      <p>{{ $t("terms.sbrhvi_group_2") }}</p>
    </div>

    <ol type="I" class="ml-2">
      <li class="word-break">
        está ciente dos termos do Regulamento do Programa Standard Brasil HVI
        (Regulamento) e compromete-se a cumprir com todas as regras nele
        inseridas;
      </li>
      <li class="word-break">
        é produtor de algodão participante do Sistema ABRAPA de Identificação
        (SAI);
      </li>
      <li class="word-break">
        orientará a usina responsável pelo beneficiamento de seus fardos de
        algodão que envie as amostras para o Laboratório com no mínimo 150
        gramas, em respeito ao artigo 20, e seus incisos, da Instrução Normativa
        IN 24 do Ministério da Agricultura e Pecuária (MAPA);
      </li>
      <li class="word-break">
        tem plena ciência de que, ao indicar os Laboratórios, estará autorizando
        que o sistema de T.I. do SBRHVI transmita ao(s) Laboratório(s)
        indicado(s) sua razão social, número de CNPJ, denominação da unidade
        produtiva, número da inscrição estadual, município e unidade da
        federação da sede da pessoa jurídica e de suas unidades produtivas
        participantes do SBRHVI;
      </li>
      <li class="word-break">
        autoriza os Laboratórios indicados a disponibilizarem dados de análise
        da classificação tecnológica e instrumental por meio de equipamento tipo
        HVI de seus fardos para o banco de dados do SBRHVI (Banco de Dados), bem
        como autoriza que os Laboratórios enviem ao Centro Brasileiro de
        Referência em Análise de Algodão (CBRA) amostras de seus fardos para a
        realização programa de algodão de reteste;
      </li>
      <li class="word-break">
        autoriza a Associação Brasileira dos Produtores de Algodão (ABRAPA)
        valer-se dos dados de qualidade de seus fardos de algodão, repassados
        pelo Laboratório, para o fim exclusivo de elaborar estatísticas, desde
        que estas não possuam a identificação do Produtor;
      </li>
      <li class="word-break">
        possui ciência de que o Laboratório encaminhará ao Banco de Dados 15
        (quinze) características intrínsecas e extrínsecas de qualidade de seus
        fardos de algodão relativas a comprimento, finura, resistência,
        uniformidade, alongamento de fibra, área de impurezas, contagens de
        impurezas, índice de fibras curtas, SCI, índice de maturidade e CSP,
        reflectância (Rd), cor (+b), color grade (CG) e folhas (LEAF);
      </li>
      <li class="word-break">
        tem ciência de que poderá autorizar a publicação das características
        intrínsecas e extrínsecas de qualidade de seus fardos de forma
        individualizada para cada negociação;
      </li>
      <li class="word-break">
        tem ciência de que após a publicação das características intrínsecas e
        extrínsecas dos lotes de fardos de algodão feita no sistema de T.I. do
        SBRHVI, esses dados ficarão disponíveis para Consulta SAI,
        independentemente dos lotes de fardos terem sido comercializados ou não;
      </li>
      <li class="word-break">
        assume todas as responsabilidades vinculadas à cessão do endereço
        eletrônico de consulta dos dados de qualidade de seus fardos e, ainda,
        por todos os atos praticados pelos usuários por mim cadastrados no
        Sistema de T.I. do SBRHVI;
      </li>
      <li class="word-break">
        adequará todos os seus relatórios que serão transferidos para o Sistema
        de T.I do SBRHVI ao formato e layout utilizado pelo SBRHVI;
      </li>
    </ol>
    <p class="mt-5 word-break">
      Ao firmar o presente Termo, o representante acima identificado atesta para
      a ABRAPA, para todos os fins e efeitos, ter os poderes necessários e
      suficientes para validamente participar do PROGRAMA STANDARD BRASIL HVI.
    </p>
  </div>
</template>
<script>
import LabelledInfo from "@/modules/core/views/components/LabelledInfo";
import { mapGetters, mapActions } from "vuex";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";

export default {
  name: "TermSbrHvi",
  props: {
    showHeader: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  components: {
    LabelledInfo
  },
  data: () => ({
    ProducerType: ProducerType,
    licenciamentoBCI: false,
    termData: {
      nome: null,
      cpf_cnpj: null,
      isCpf: null,
      rg: null,
      endereco: null,
      cidade: null,
      uf: null,
      associacao_estadual: {
        sigla: null,
        razao_social: null
      },
      composicao: []
    },
    modes: {
      [ProducerType.PRODUCER.id]: {
        label: ProducerType.PRODUCER.name,
        productionType: "COTONICULTORES",
        type: ProducerType.PRODUCER.id
      },
      [ProducerType.GROUP.id]: {
        label: ProducerType.GROUP.name,
        productionType: "GRUPO DE COTONICULTORES",
        type: ProducerType.GROUP.id
      }
    }
  }),
  async mounted() {
    await this.fetchTerm();
  },
  computed: {
    ...mapGetters({
      inviteDetail: "invited/getInviteDetail"
    }),
    mode() {
      return this.modes[this.inviteDetail.tipo_produtor];
    }
  },
  methods: {
    ...mapActions("invite", ["fetchSbrHviTerm"]),
    ...mapActions(["toggleSnackbar"]),
    async fetchTerm() {
      try {
        this.termData = await this.fetchSbrHviTerm(this.inviteDetail);

        this.termData.isCpf = this.termData.cpf_cnpj?.length <= 14;
      } catch (error) {
        this.toggleSnackbar({
          text: error.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.word-break {
  word-break: break-word;
}
</style>
