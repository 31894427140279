<template>
  <div>
    <h3 class="black--text mb-5">
      {{
        $t("text.titulo_desejo_recusar_somente_programa", {
          programa: " ABR/BCI e Aceitar o SBRHVI"
        })
      }}
    </h3>

    <p class="black--text">
      {{ $t("text.info_recusar_programa_abrbci_aceitar_sbrhvi") }}
    </p>

    <v-row>
      <v-col cols="12" md="6">
        <v-btn
          block
          color="primary"
          outlined
          @click="
            $confirmDialog.open(
              $t('text.confirmar_recusar_convite', { programa: 'ABR/BCI' }),
              confirmDialog
            )
          "
          >{{
            $t("buttons.refuse_program", {
              program: "ABR/BCI e aceitar participar do SBRHVI"
            })
          }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NotionMixin from "@/modules/invited/mixins/notion-mixin";

import { mapActions } from "vuex";

export default {
  name: "RefuseOnlyAbrbci",

  mixins: [NotionMixin],

  methods: {
    ...mapActions("invited", ["setTermPageCurrentStep"]),

    confirmDialog() {
      this.callSendNotionAbrBci(false, false, true, () => {
        this.setTermPageCurrentStep(2);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
