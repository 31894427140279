<template>
  <v-card>
    <v-card-text>
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="(step, n) in steps">
            <v-stepper-step
              :key="step.step"
              :complete="currentStep > step.step"
              :step="step.step"
              color="primary"
            >
              {{ step.label }}
            </v-stepper-step>
            <v-divider
              v-if="n < steps.length - 1"
              :key="`step-divider-${step.step}`"
            ></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-card-text>

    <stepper-content-abrbci
      v-if="currentStep === 1 && canSeeStepperContentAbrbci"
    />

    <stepper-content-abrbci-sbrhvi
      v-if="currentStep === 1 && canSeeStepperContentAbrbciSbrhvi"
    />

    <stepper-content-sbrhvi
      v-if="currentStep === 1 && canSeeStepperContenSbrhvi"
    />
    <stepper-content-confirmation v-if="currentStep === 2" />
  </v-card>
</template>

<script>
import StepperContentAbrbci from "@/modules/invited/views/components/term-stepper/StepperContentAbrbci";
import StepperContentAbrbciSbrhvi from "@/modules/invited/views/components/term-stepper/StepperContentAbrbciSbrvhi";
import StepperContentSbrhvi from "@/modules/invited/views/components/term-stepper/StepperContentSbrhvi";
import StepperContentConfirmation from "@/modules/invited/views/components/term-stepper/StepperContentConfirmation";

import { InvitedModes } from "@/modules/invited/enums/invited-mode.enum";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Terms",

  components: {
    StepperContentAbrbci,
    StepperContentAbrbciSbrhvi,
    StepperContentSbrhvi,
    StepperContentConfirmation
  },

  computed: {
    ...mapGetters({
      inviteMode: "invited/getInviteMode",
      termPageCurrentStep: "invited/getTermPageCurrentStep",
      steps: "invited/getTermPageStepperSteps"
    }),

    currentStep: {
      get() {
        return this.termPageCurrentStep;
      },
      set(newVal) {
        this.setTermPageCurrentStep(newVal);
      }
    },

    canSeeStepperContentAbrbci() {
      return [
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED.id
      ].includes(this.inviteMode);
    },

    canSeeStepperContentAbrbciSbrhvi() {
      return [
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED.id
      ].includes(this.inviteMode);
    },

    canSeeStepperContenSbrhvi() {
      return InvitedModes.SBRHVI_UNACCEPTED.id === this.inviteMode;
    }
  },

  methods: {
    ...mapActions("invited", ["setTermPageCurrentStep"])
  }
};
</script>

<style></style>
