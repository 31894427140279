<template>
  <div>
    <v-card-text>
      <term-abr-bci :state="termAbrBciState" />
    </v-card-text>

    <v-card-actions class="d-flex justify-end pb-5 pr-5">
      <v-btn
        color="refuse"
        class="px-4 white--text"
        @click="
          $confirmDialog.open(
            'Você tem certeza de que deseja recusar o Convite para o Programa ABR/BCI?',
            handleRefuseTerm
          )
        "
      >
        Desejo recusar o Programa ABR/BCI
      </v-btn>
      <optional-tooltip
        :hide="!invalidBciNotion"
        :text="$t('text.info_definir_parecer_bci')"
        ><v-btn
          color="accept"
          class="mx-4 white--text"
          @click="
            $confirmDialog.open(
              'Você confirma a adesão ao(s) Programa(s)?',
              handleAcceptTerm
            )
          "
          :disabled="invalidBciNotion"
          >Estou ciente e desejo aderir ao(s) Programa(s)</v-btn
        >
      </optional-tooltip>
    </v-card-actions>
  </div>
</template>

<script>
import TermAbrBci from "@/modules/invited/views/components/abr-bci/TermAbrBci";
import OptionalTooltip from "@/modules/core/views/components/OptionalTooltip";
import NotionMixin from "@/modules/invited/mixins/notion-mixin";

import { mapActions } from "vuex";

export default {
  name: "StepperContentAbrbci",

  components: {
    TermAbrBci,
    OptionalTooltip
  },

  mixins: [NotionMixin],

  data: () => ({
    termAbrBciState: {
      acceptBci: null
    }
  }),

  mounted() {
    this.setTermPageFirstStepLabel("Termos de Adesão ABR/BCI");
  },

  computed: {
    invalidBciNotion() {
      return (
        this.termAbrBciState.acceptBci !== "yes" &&
        this.termAbrBciState.acceptBci !== "no"
      );
    }
  },

  methods: {
    ...mapActions("invited", [
      "setTermPageFirstStepLabel",
      "setTermPageCurrentStep"
    ]),

    handleAcceptTerm() {
      const acceptBci = this.termAbrBciState.acceptBci === "yes";
      this.callSendNotionAbrBci(true, acceptBci, null, () => {
        this.setTermPageCurrentStep(2);
      });
    },

    handleRefuseTerm() {
      this.callSendNotionAbrBci(false, false, null, () => {
        this.setTermPageCurrentStep(2);
      });
    }
  }
};
</script>

<style></style>
