<template>
  <v-dialog v-model="state.active" width="980" scrollable persistent>
    <v-card>
      <v-card-title class="primary--text">{{
        $t("text.titulo_recusar_programa")
      }}</v-card-title>

      <v-card-text>
        <h2 class="black--text my-5">
          {{ $t("text.titulo_qual_programa_recusar") }}
        </h2>

        <div class="mt-5">
          <refuse-abrbci-and-sbrhvi />
        </div>

        <div class="mt-16">
          <refuse-only-abrbci />
        </div>

        <div class="mt-16">
          <refuse-only-sbrhvi />
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-center align-center">
        <v-btn text color="primary" @click="state.active = false">{{
          $t("labels.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RefuseAbrbciAndSbrhvi from "@/modules/invited/views/components/refuse-programs/RefuseAbrbciAndSbrhvi";
import RefuseOnlyAbrbci from "@/modules/invited/views/components/refuse-programs/RefuseOnlyAbrbci";
import RefuseOnlySbrhvi from "@/modules/invited/views/components/refuse-programs/RefuseOnlySbrhvi";

export default {
  name: "RefusePrograms",

  components: {
    RefuseAbrbciAndSbrhvi,
    RefuseOnlyAbrbci,
    RefuseOnlySbrhvi
  },

  props: {
    state: {
      type: Object,
      required: true,
      validator: function(values) {
        const keys = ["active"];
        return Object.keys(values).every(value => keys.includes(value));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
