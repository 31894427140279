var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('term-sbr-hvi')],1),_c('v-card-actions',{staticClass:"d-flex justify-end pb-5 pr-5"},[_c('v-btn',{staticClass:"px-4 white--text",attrs:{"color":"refuse"},on:{"click":function($event){return _vm.$confirmDialog.open(
          {
            title:
              'Você tem certeza de que deseja recusar o Convite para o Programa SBRHVI?',
            infoText:
              'A partir de 2021, todos os produtores que aderirem ao ABR, ABR/BCI, automaticamente, aderem ao SBRHVI por prazo indeterminado.'
          },
          _vm.handleRefuseTerm
        )}}},[_vm._v(" Desejo recusar o Programa SBRHVI ")]),_c('v-btn',{staticClass:"px-4 white--text",attrs:{"color":"accept"},on:{"click":function($event){return _vm.$confirmDialog.open(
          'Você confirma a adesão ao Programa SBRHVI?',
          _vm.handleAcceptTerm
        )}}},[_vm._v(" Estou ciente e desejo aderir ao Programa SBRHVI")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }