<template>
  <div>
    <v-card-text>
      <term-abr-bci :state="termAbrBciState" />
      <div class="my-10">
        <v-divider></v-divider>
      </div>
      <term-sbr-hvi :showHeader="false" />
    </v-card-text>

    <refuse-programs :state="refuseProgramsState" />

    <v-card-actions class="d-flex justify-end pb-5 pr-5">
      <v-btn color="refuse" class="px-4 white--text" @click="handleRefuseTerm">
        {{ refuseBtnLabel }}
      </v-btn>

      <optional-tooltip
        :hide="!invalidBciNotion"
        :text="$t('text.info_definir_parecer_bci')"
        ><v-btn
          color="accept"
          class="mx-4 white--text"
          @click="
            $confirmDialog.open(
              'Você confirma a adesão ao(s) Programa(s)?',
              handleAcceptTerm
            )
          "
          :disabled="invalidBciNotion"
          >Estou ciente e desejo aderir ao(s) Programa(s)</v-btn
        >
      </optional-tooltip>
    </v-card-actions>
  </div>
</template>

<script>
import TermAbrBci from "@/modules/invited/views/components/abr-bci/TermAbrBci";
import TermSbrHvi from "@/modules/invited/views/components/sbrhvi/TermSbrHvi";
import OptionalTooltip from "@/modules/core/views/components/OptionalTooltip";
import NotionMixin from "@/modules/invited/mixins/notion-mixin";
import RefusePrograms from "@/modules/invited/views/components/refuse-programs/RefusePrograms";

import { InvitedModes } from "@/modules/invited/enums/invited-mode.enum";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "StepperContentAbrbciSbrhvi",

  components: {
    TermAbrBci,
    TermSbrHvi,
    OptionalTooltip,
    RefusePrograms
  },

  mixins: [NotionMixin],

  data: () => ({
    termAbrBciState: {
      acceptBci: null
    },
    refuseProgramsState: {
      active: false
    }
  }),

  mounted() {
    this.setTermPageFirstStepLabel("Termos de Adesão ABR/BCI e SBRHVI");
  },

  computed: {
    ...mapGetters({
      inviteMode: "invited/getInviteMode"
    }),

    isInvitedOnlyToAbrBci() {
      return [
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED.id
      ].includes(this.inviteMode);
    },

    refuseBtnLabel() {
      return this.isInvitedOnlyToAbrBci
        ? "Desejo recusar o Programa ABR/BCI"
        : "Desejo recusar algum Programa";
    },

    invalidBciNotion() {
      return (
        this.termAbrBciState.acceptBci !== "yes" &&
        this.termAbrBciState.acceptBci !== "no"
      );
    }
  },

  methods: {
    ...mapActions("invited", [
      "setTermPageFirstStepLabel",
      "setTermPageCurrentStep"
    ]),

    handleAcceptTerm() {
      const acceptBci = this.termAbrBciState.acceptBci === "yes";
      this.callSendNotionAbrBci(true, acceptBci, true, () => {
        this.setTermPageCurrentStep(2);
      });
    },

    handleRefuseTerm() {
      if (this.isInvitedOnlyToAbrBci) {
        this.prepareRefuseOnlyAbr();
      } else {
        this.openRefusePrograms();
      }
    },

    prepareRefuseOnlyAbr() {
      this.$confirmDialog.open(
        {
          title:
            "Você tem certeza de que deseja recusar o Convite para o Programa ABR/BCI?",
          infoText:
            "Ao recusar o convite para o Programa ABR/BCI, o Responsável Líder SBRHVI será convidado para responder ao convite do SBRHVI."
        },
        this.handleRefuseOnlyAbr
      );
    },

    handleRefuseOnlyAbr() {
      this.callSendNotionAbrBci(false, false, null, () => {
        this.setTermPageCurrentStep(2);
      });
    },

    openRefusePrograms() {
      this.refuseProgramsState.active = true;
    }
  }
};
</script>

<style></style>
