<template>
  <div>
    <v-card-text>
      <term-confirmation />
    </v-card-text>
  </div>
</template>

<script>
import TermConfirmation from "@/modules/invited/views/components/TermConfirmation";

export default {
  name: "StepperContentConfirmation",

  components: {
    TermConfirmation
  }
};
</script>

<style></style>
